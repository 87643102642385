import React from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaYoutube } from 'react-icons/fa';
import { ReactComponent as TurboSquidLogo } from './turbosquid-logo.svg';

const Header = () => (
  <header className="bg-[#0B0B0B] w-full shadow-lg font-inter">
    <div className="max-w-6xl mx-auto px-4 py-6">
      <div className="flex flex-col items-center text-center lg:text-left lg:flex-row lg:items-start lg:justify-between">
        {/* Profile Section */}
        <div className="flex flex-col lg:flex-row items-center lg:items-start gap-6 w-full lg:flex-grow">
          {/* Profile Picture */}
          <div className="relative group shrink-0">
            <div className="absolute -inset-0.5 bg-white/10 rounded-lg opacity-50 group-hover:opacity-100 blur transition duration-500"></div>
            <img
              className="relative w-[120px] h-[120px] rounded-lg object-cover grayscale hover:grayscale-0 transition-all duration-500"
              src="https://images.ctfassets.net/srfwb65ha3ql/5g4ASAU2f7EDOlwR8baH5y/6904f787c991f2284253f0dc2a178bd7/pic.jpg"
              alt="Gianluca Gatto Profile"
              loading="eager"
            />
          </div>
          {/* Name and Description */}
          <div className="flex flex-col items-center lg:items-start justify-center min-h-[120px] gap-3 mt-4 lg:mt-0">
            <h1 className="text-3xl font-bold text-white tracking-tight">
              <Link to="/" className="hover:text-gray-400 transition-colors duration-300">
                GIANLUCA GATTO
              </Link>
            </h1>
            <p className="text-sm text-gray-400 max-w-md leading-relaxed text-center lg:text-left">
              I'm Gianluca, a 3D artist using Blender to craft bold, immersive digital worlds. Blending creativity with precision, I push the boundaries of what's possible in digital art.
            </p>
          </div>
        </div>

        {/* Navigation */}
        <nav className="flex items-center justify-center lg:justify-start gap-2 mt-4 lg:mt-0 lg:ml-auto">
          {/* Contact Button */}
          <Link
            to="/contact"
            aria-label="Contact"
            className={`
              group relative flex h-10 w-10 items-center justify-center 
              overflow-hidden rounded-lg
              transition-all duration-300 hover:w-32
              focus:outline-none focus:ring-2 focus:ring-yellow-300/20
              before:absolute before:inset-0 
              before:bg-gradient-to-r before:from-yellow-200 before:via-yellow-100 before:to-yellow-200
              before:animate-shimmer before:bg-[length:200%_100%]
              after:absolute after:inset-[1px] after:rounded-[7px] 
              after:bg-gradient-to-r after:from-yellow-500 after:to-yellow-600
              after:transition-colors after:duration-300
              hover:after:from-yellow-400 hover:after:to-yellow-500
              [&:not(:hover)]:animate-pulse
              [&:not(:hover)]:shadow-[0_0_15px_rgba(255,215,0,0.5)]
              hover:shadow-[0_0_30px_rgba(255,215,0,0.8)]
              hover:animate-none
            `}
          >
            <div className="relative z-10 flex items-center justify-center w-full h-full transition-all duration-300 group-hover:justify-start group-hover:pl-3">
              <FaEnvelope className="h-4 w-4 text-yellow-100 transition-transform duration-300 group-hover:scale-90" aria-hidden="true" />
            </div>
            <span className="absolute right-3 z-10 translate-x-full text-xs font-medium text-yellow-100 opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:opacity-100">
              Contact
            </span>
          </Link>
          {/* YouTube Button */}
          <a
            href="https://www.youtube.com/@gianlucagatto1"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="YouTube"
            className="group relative flex h-10 w-10 items-center justify-center overflow-hidden rounded-lg transition-all duration-300 hover:w-32 focus:outline-none"
          >
            <span className="absolute inset-0 bg-red-600 transition-transform transform group-hover:scale-105 duration-300"></span>
            <div className="relative z-10 flex items-center justify-center w-full h-full transition-all duration-300 group-hover:justify-start group-hover:pl-3">
              <FaYoutube className="h-4 w-4 text-white transition-transform duration-300 group-hover:scale-90" aria-hidden="true" />
            </div>
            <span className="absolute right-3 z-10 translate-x-full text-xs font-medium text-white opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:opacity-100">
              YouTube
            </span>
          </a>
          {/* TurboSquid Button */}
          <a
            href="https://www.turbosquid.com/Search/Artists/GianlucaGatto"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="TurboSquid"
            className="group relative flex h-10 w-10 items-center justify-center overflow-hidden rounded-lg transition-all duration-300 hover:w-32 focus:outline-none"
          >
            <span className="absolute inset-0 bg-white transition-transform transform group-hover:scale-105 duration-300"></span>
            <div className="relative z-10 flex items-center justify-center w-full h-full transition-all duration-300 group-hover:justify-start group-hover:pl-3">
              <TurboSquidLogo className="w-6 h-6" aria-hidden="true" />
            </div>
            <span className="absolute right-3 z-10 translate-x-full text-xs font-medium text-black opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:opacity-100">
              TurboSquid
            </span>
          </a>
        </nav>
      </div>
    </div>
  </header>
);

export default Header;